export const caseTabsEnum = {
    chats: 'chats',
    plan: 'plan',
    history: 'history',
    documents: 'documents',
    cards: 'cards',
    settings: 'settings',
};

export const planTabsEnum = [
    {
        key: 'deadline',
        label: 'По сроку',
    },
    {
        key: 'status',
        label: 'По статусу',
    },
];
